import { LoginButtonSVG } from "../assets/ButtonsSVG"

const SpendToPlayBTN = (gsClass) => {
    return (
        <a href="/account/login?return=/pages/sweepstakes" type="button">
            { 
                LoginButtonSVG(`${gsClass} gs-w-[218px] gs-h-[74px] lg:gs-w-[421px] lg:gs-h-[142px]`)
            }
        </a>
    )
}

export { SpendToPlayBTN }