import { ChuckSVG } from "../assets/ChuckSVG"

const Chuck = () => {
    return(
        <div id="chuck" className="gs-absolute gs-z-50 gs-mx-auto gs-mt-6 lg:gs-mt-12 gs-w-full gs-flex gs-justify-center">
            { ChuckSVG('gs-w-[174px] gs-h-[168px] lg:gs-w-[440px] lg:gs-h-[425px] gs-ml-6 lg:gs-ml-[5.5rem]') }
        </div>
    )
}

export default Chuck;