import Reward from './Reward';

export default function History({ items }) {
    return (
        <div className='gs-container gs-mx-auto gs-max-w-2xl gs-flex gs-flex-col gs-justify-center gs-mt-12'>
            <div className='gs-text-center gs-text-lightYellow gs-font-bold lg:gs-text-[2rem] gs-uppercase'>You're A Winner</div>
            <div className='gs-text-center gs-text-white gs-text-lg gs-montserrat'>
                All rewards will be redeemed<br className='sm:gs-hidden' /> at the end of the promotion
                <div className='gs-text-xs'><sup>*</sup>Digital Patterns will be available in my account pages 24 hours after winning.</div>
            </div>

            <div className='gs-mx-4 gs-my-4 sm:gs-m-12 gs-grid gs-grid-cols-1 sm:gs-grid-cols-3 gs-gap-8 sm:gs-gap-12'>
                {
                    items && items.map((item, key) => (
                    (  
                        <Reward reward={item} key={key} />
                    )
                    ))
                }
            </div>
        </div>
    )
}