import parse from 'html-react-parser';
import { goldenTicketCounterSVG, ticketCounterSVG } from '../assets/CounterSVG';

const Counter = ({dir, text, count, imagePendantLeft, imagePendantRight}) => {

    const countDisplay = (c) => {
        let count = c;
        
        if (c <= 9) count = `<span className='gs-text-black gs-textGold-shadow'>0</span>${c}`;
        
        if(c > 99) {
            return `<div className='gs-text-[42px] md:gs-text-6xl'>${count}</div>`;
        }else{
            return `<div className='gs-text-[42px] md:gs-text-6xl lg:gs-text-7xl'>${count}</div>`;
        }
    }

    const orderClass = (d) => {
        return (d === 'left') ? `gs-order-2 lg:gs-order-1` : `gs-order-2`;
    }

    const orderClassAlt = (d) => {
        return (d === 'left') ? `gs-order-1 lg:gs-order-2` : `gs-order-1`;
    }
    

    return(
        <div className={`gs-flex gs-flex-col lg:gs-flex-row lg:gs-justify-start gs-gap-1 ${dir === 'left' ? 'lg:-gs-mx-12' : 'lg:-gs-mx-16'}`}>
            <div className={`${orderClass(dir)} gs-w-min`}>
                <div className={`gs-flex ${dir === 'left' ? 'gs-justify-end' : 'gs-justify-start'} gs-items-center gs-h-full`}>
                    <span className={`gs-text-white gs-montserrat ${dir === 'left' ? 'gs-text-left lg:gs-text-right' : 'gs-text-right lg:gs-text-left'} gs-font-bold gs-w-full gs-text-[15px] lg:gs-text-[25px]`}>{ text }</span>
                </div>
            </div>

            <div className={`gs-relative ${orderClassAlt(dir)}`}>
                {
                    dir === 'left'
                    ? goldenTicketCounterSVG('gs-object-cover gs-w-[72px] gs-h-[78px] lg:gs-w-[144px] lg:gs-h-[155px]')
                    : ticketCounterSVG('gs-object-cover gs-w-[75px] gs-h-[78px] lg:gs-w-[148px] lg:gs-h-[155px]')
                } 
                <div className={`counter gs-flex-1 gs-absolute gs-left-0 gs-right-0 gs-top-0 gs-bottom-0 m-auto gs-text-textGold gs-montserrat gs-font-black gs-flex gs-items-center gs-justify-center`}>
                    <div className='lg:-gs-mt-4'>
                        { 
                            parse(countDisplay(count))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Counter;