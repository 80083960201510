import { SpendToPlayBTN } from "../components/Buttons";

const SpendToPlay = (props) => {
    return (
        <div className="gs-absolute -gs-bottom-[5.5rem] lg:-gs-bottom-10 gs-z-50 gs-mx-auto gs-w-full gs-flex gs-justify-center">
            {SpendToPlayBTN(`gs-z-50`)}
        </div>
    )
 }

export default SpendToPlay;