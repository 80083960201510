import { SpendToPlaySVG } from "../assets/SpendToPlaySVG";

const SpendToPlayMsg = (props) => {
    return (
        <div className='gs-absolute gs-z-50 gs-mx-auto gs-top-0 gs-left-0 gs-right-0 gs-bottom-0 gs-flex gs-justify-center gs-items-center'>
            <div className="gs-absolute gs-montserrat gs-font-bold gs-text-xs gs-w-[163px] gs-h-[35px] -gs-mt-[5.5rem] gs-ml-6 lg:gs-text-[2rem] lg:gs-leading-10 lg:gs-w-[373px] lg:gs-h-[78px] lg:-gs-mt-48 lg:gs-ml-auto gs-text-[#D62027]">{props.spendTag}</div>
            { SpendToPlaySVG(`gs-ml-6 gs-w-[219px] gs-h-[165px] -gs-mt-2 lg:gs-w-[502px] lg:gs-h-[377px]`) }
        </div>
    )
 }

export default SpendToPlayMsg;