import React from 'react';

export default function Reward({ reward }) {
  return (
    <div data-prize-code={reward.prize.code} data-prize-coupon={reward.prize.coupon}>
      <img src={reward.prize.image_url} alt='' className="gs-w-2/3 sm:gs-w-full gs-h-56 sm:gs-h-40 gs-mx-auto gs-aspect-square gs-object-cover" />
      <div className="gs-mt-8 gs-text-white gs-montserrat gs-text-lg gs-break-words gs-text-center">{reward.prize.name}</div>
    </div>
  );
}
