import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const el = document.getElementById('creativity-sweepstakes-app');
//const attributes = el.dataset;

let attributes = {
  shopifyCustomerId: 4155420377221,
  // shopifyCustomerId: 3935952044067,
  // baseUrl: 'http://localhost:9887',
  chUserId: '',
  baseUrl: 'https://staging.creativity-sweepstakes.apps.creativity.inc',
  apiToken: 'bXNxYw==',
  spendToPlay: 'Get a ticket to play for Every $10 you spend'
}

const root = ReactDOM.createRoot(el);

root.render(
  <App {...attributes} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
