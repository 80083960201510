import React from 'react';
import parse from 'html-react-parser';

import { PlayAgainButton, PlayButtonSVG, WinnerRibbon, WinnerTitle } from '../assets/ButtonsSVG';



const PlayButton = (props) => {
    const { ticketClicker, winner, ticketCount, reward, playStatus } = props;

    const rewardTitle = (t) => {
        if (t.includes('Digital Download')){
            let clean_title = t.split('-');
            let patternTitle = clean_title[1].split('by')[0];
            return `${clean_title[0].trim()}<br />${patternTitle}`;
        }
        return t;
    }

    return (
        <div className={`gs-absolute gs-z-50 ${!winner ? '-gs-bottom-[5.5rem] lg:-gs-bottom-10' : 'gs-bottom-[2rem]'} gs-w-full gs-flex gs-justify-center`}>
            {
                !winner && (
                    <button type="button" onClick={() => ticketClicker()} disabled={playStatus} className='gs-mx-auto'>
                        { PlayButtonSVG(`gs-w-[138px] gs-h-[70px] lg:gs-w-auto lg:gs-h-auto`) }
                    </button>
                )
            }
            {
                winner && (
                    <div className="gs-flex gs-flex-col gs-items-center">
                        <div className='gs-relative'>
                            { WinnerTitle(`gs-relative gs-z-50 gs-mx-auto -gs-mb-12 -gs-mt-2 gs-z-50 gs-w-[190px] gs-h-[62px]`) }
                            { WinnerRibbon(`gs-w-[578px]`) }
                            {
                                reward?.prize && (
                                    <div className='gs-absolute gs-top-0 gs-text-center gs-mt-14 lg:gs-ml-[4.6rem] gs-w-[432px] gs-overflow-hidden'>
                                        <span className='gs-montserrat gs-font-bold gs-text-[2rem] gs-text-white'>{ parse(rewardTitle(reward.prize.name)) }</span>
                                    </div>
                                )
                            }
                        </div>
                        {
                            ticketCount > 0 && (
                                <button className='' onClick={() => ticketClicker()}>
                                    { PlayAgainButton(`gs-w-[162px] -gs-mt-8`) }
                                </button>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default PlayButton;